body{
    font-size: 0.75rem;
}
.btn{
    font-size: 0.75rem;
    padding: 0.3rem 0.4rem;
}
.sidebar-nav .nav-title{
    margin-top: 0.3rem;
}
 
.sidebar-nav .nav-link{
    padding: 0.5rem;
}
.header{
    padding: 3px; 
    display: block;
}
.header .container:first-child, .header .container-fluid:first-child, .header .container-sm:first-child, .header .container-md:first-child, .header .container-lg:first-child, .header .container-xl:first-child, .header .container-xxl:first-child{
    padding: 0.1rem;
}
 
.form-select{
    font-size: 0.75rem;
}
.MuiInputBase-root{
    font-size: 0.75rem;
}
#combo-box-demo{
    font-size: 0.75rem;
}
.tooltip-inner{
    font-size: 0.75rem;
    background-color: #13033f;
}
 
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    font-size: 0.7rem;
    padding: 0;
}
/* .MuiInputBase-input{
    font-size: 0.8rem !important;
    padding: 8px 10px !important;
    width: '100px' !important;
} */
.card-header{
    font-size: 0.8rem;
    padding: 5px 10px;
  }
/* .MuiOutlinedInput-input .MuiInputBase-inputAdornedEnd{
    width: '100px' !important;
} */

.list-group-item.active.unasigned{
    background-color: #332f32;
}
.list-group-item.active.asigned{
    background-color: #118d2c;
}

.modal{
    font-size: 0.7rem;
}

/* .rdrDateRangePickerWrapper{
    position: relative;
    z-index: 5000 !important;
} */
.daterangepickerbox {
    display: block; /* Make sure it's set to 'block' or 'flex' for visibility */
  }

