.fitsairLoader {
  background-color: #070A52;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: 1500;
  display: flex;
  align-items: center;
}

.Loader {
  margin: auto;
  border: 10px solid #C70039;
  border-radius: 50%;
  border-top: 10px solid;
  width: 100px;
  height: 100px;
  animation: spinner 4s linear infinite;
}

.ImageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:darkgrey;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImageContainer img{
    width: 70px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
